@font-face {
  font-family: 'RobotoMono';
  src: url('./RobotoMono-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoMono';
  src: url('./RobotoMono-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}