.routes-container {
  display: flex;
  flex-direction: column; /* Stack header and content vertically */
  height: 100vh;
  width: 100%; 
  background-color: black; 
}

/* Sidebar styling */
.sidebar {
  display: flex;
  width: 20%;
  min-width: 120px; 
  height: 100%;
}

/* Content area styling */
.routes-content {
  flex-grow: 1; 
  display: flex;
  flex-direction: column; 
  padding-right: 60px;
}

@media screen and (max-width: 1000px) {
  .sidebar{
    display: none;
  }

  .routes-content {
    flex-grow: 1; 
    display: flex;
    flex-direction: column; 
    padding: 0 40px;
  }
}

@media screen and (max-width: 450px) {
  .routes-content {
    padding: 5px;
  }
  
  .routes-container {
    max-width: 100%;
  }
}
