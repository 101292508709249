.st-container {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  border-radius: 15px;
  background-color: rgb(33, 34, 37);
  flex-grow: 1;
  flex: 1;
  padding: 35px;
  max-width: 100%;
}

.toolTipContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 65px;
}

.st-box {
  border-radius: 15px;
  max-height: 100%;
}

.inputButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #191A1E;
  border: 1px solid white;
  flex: 1;
  border-radius: 10px;
  padding: 5px 5px 5px 13px;
  gap: 10px;
}

.STBottomContainer {
  flex-direction: column;
}

.VDContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#svg svg{
  width: auto;
}

#sets {
  color: rgb(95, 106, 200);
  font-weight: 700;
  cursor: pointer;
}
 #union {
  color: rgb(168, 109, 140);
  font-weight: 700;
  cursor: pointer;
 }
 #intersection {
  color: rgb(185, 117, 117);
  font-weight: 700;
  cursor: pointer;
 }

@media screen and (max-width: 450px) {
  .st-container {
    max-width: 100%;
    padding: 20px 10px;
    margin-top: 50px;
  }

  .inputButtonContainer {
    max-width: 100%;
    overflow: auto;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .st-box {
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    max-height: 100%;
    gap: 20px
  }

  .toolTipContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0px;
    height: 65px;
  }
}
