.sidebar {
  width: 20%;
  /* padding-top: 15px; */
  padding: 0 10px;
  max-width: 225px;
  display: flex;
  flex-direction: row; 
  align-items: flex-start;
}

.sm-text {
  transition: transform 0.3s ease; 
  transform-origin: left;
  line-height: 200%;
  padding-left: 10px;
  color: white;
}

/* Only apply the transformation on hover */
.sidebarBox:hover .sm-text {
  cursor: pointer;
  transform: scale(1.2);
}

.sidebarBox:active .sm-text{
  transform: scale(.9);
}

/* Hide hamburger icon on larger screens */
.hamburger {
  display: none;
}

/* Hide sidebar and show hamburger icon on small screens */
@media screen and (max-width: 1000px) {
  .sidebar {
    display: none;
  }

  .hamburger {
    display: block;
  }

  .hamburerBox {
    flex: 1;
    flex-direction: column;
    align-items: center;
    height: 100%; 
  }
}
