.tippy-box[data-animation='header-custom-animation'] {
  transform: scale(0.8) !important;
}

.tippy-box[data-animation='header-custom-animation'].tippy-enter {
  transform: scale(1) !important;
}

.tippy-box[data-animation='header-custom-animation'].tippy-exit {
  transform: scale(0.8) !important;
}

@keyframes rainbow-text {
  0% { color: rgb(95, 106, 200); }
  16% { color: rgb(168, 109, 140); }
  32% { color: rgb(185, 117, 117); }
  48% { color: rgb(255, 166, 0); }
  64% { color: rgb(185, 117, 117); }
  80% { color: rgb(168, 109, 140); }
  100% { color: rgb(95, 106, 200); }
}

.pi-animation {
  animation: rainbow-text 3s linear infinite;
}

.hamburgerIcon {
  display: none;
}

.dropdown {
  width: auto;
  height: 'auto';
  padding: 20px;
  background-color: #eee;
  border-radius: 15px;
}

.headerText {
  line-height: 200%;
  padding: 5px 15px;
  color: black;
  cursor: pointer;
}

/* Only apply the transformation on hover */
.sidebarBox:hover .headerText{
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.logo {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  margin-left: 10px;
}

#pi-7 {
  position: absolute;
  cursor: pointer;
  top: -25px;
  left: 0;
  color: #355070;
  z-index: 10;
}

#pi-5 {
  position: absolute;
  cursor: pointer;
  top: -28px;
  left: 4px;
  color: #6d597a;
  z-index: 20;
}

#pi-4 {
  position: absolute;
  cursor: pointer;
  top: -31px;
  left: 8px;
  color: #b56576;
  z-index: 30;
}

#pi-3 {
  position: absolute;
  cursor: pointer;
  top: -34px;
  left: 12px;
  color: #e56b6f;
  z-index: 40;
}

#pi-2 {
  position: absolute;
  cursor: pointer;
  top: -37px;
  left: 16px;
  color: #eaac8b;
  z-index: 50;
}

#pi-1 {
  position: absolute;
  cursor: pointer;
  top: -40px;
  left: 20px;
  color: white;
  z-index: 60;
}

@keyframes fallDown {
  0% { transform: translateY(-100px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}

.logo > div {
  animation: fallDown 0.5s ease-in-out forwards;
}

.logo > div:nth-child(6) { animation-delay: 0.1s; }
.logo > div:nth-child(5) { animation-delay: 0.2s; }
.logo > div:nth-child(4) { animation-delay: 0.3s; }
.logo > div:nth-child(3) { animation-delay: 0.4s; }
.logo > div:nth-child(2) { animation-delay: 0.5s; }
.logo > div:nth-child(1) { animation-delay: 0.6s; }




@media screen and (max-width: 1000px) {
  .hamburgerIcon {
    display: block;
    margin-left: 20px;
  }
}

